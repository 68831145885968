import Image from 'react-bootstrap/Image';

function ImageLink(props) {   
    
    return (
       
        <a href={props.url} onClick={props.onClick} target={props.target??'_self'}>
        <div className='imageLink' style={{position:"relative", textAlign:"center", margin:"2px"}}>
            <Image src={process.env.REACT_APP_OBJ_URL+props.image} alt="" fluid style={{filter:"brightness(50%)", minHeight:"150px", display:props.hideImage?'none':'' }} />               
            <div style={{textAlign:"center", width:"100%", position:"absolute", top:"30%" }}>
                <Image src={process.env.REACT_APP_OBJ_URL+props.icon} className='btn-primary' style={{width:"3.2rem" }} roundedCircle/>
                <h3 style={{color:props.color}}>{props.text}</h3>
            </div>
        </div>
        </a>
        
    );

}

export default ImageLink;