import { useEffect } from 'react';

function Donate (props) {
    useEffect(() => {
        const script = document.createElement('script');
      
        script.src = "/paypal_donate.js"; // the donate button is configured in this js file.
        script.async = true;
      
        document.body.appendChild(script);
      
        return () => {
          document.body.removeChild(script);
        }
      }, []);

    return (
        <>  
            <div id="paypal-donate-button-container" style={{maxWidth:'4rem'}}></div>
        </>
    );
}

export default Donate;