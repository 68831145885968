import firebase from "../firebase"

export const getDocuments = async (collection,query) => {
    const currentUser = firebase.auth().currentUser
    return await fetch(`${process.env.REACT_APP_API_URL}/col/${collection}?${query}`,
            {
                method: 'get',
                headers: {
                    'X-Firebase-AppCheck': (await firebase.appCheck().getToken(/* forceRefresh= */ true)).token,
                    'Authorization': currentUser ? await currentUser.getIdToken(true): '',
                    'Cache-Control': 'public, max-age=300',
                },
            }
            )
            .then((res) => {
                if(res.ok) return res.json()
                else return []
            })
            .catch((error) => {
                console.error(error)
                return []
            }); 
       
}

export const getDocumentById = async (collection,docId) => {
    const currentUser = firebase.auth().currentUser
    return await fetch(`${process.env.REACT_APP_API_URL}/col/${collection}/doc/${docId}`,
            {
                method: 'get',
                headers: {
                    'X-Firebase-AppCheck': (await firebase.appCheck().getToken(/* forceRefresh= */ true)).token,
                    'Authorization': currentUser ? await currentUser.getIdToken(true): '',
                    'Cache-Control': 'public, max-age=300',
                },
            })
            .then((res) => {
                if(res.ok) return res.json()
                else return {}
            })
            .catch((error) => {
                console.error(error)
                return {}
            }); 
       
}

export const createDocument = async (collection, document) => {
    if(!(firebase.auth().currentUser)) throw new Error("User not authorized.")
    
    return await fetch(`${process.env.REACT_APP_API_URL}/col/${collection}/doc`,
            {
                method: 'post',
                headers: {
                    'Content-Type':'application/json', 
                    'Authorization': await firebase.auth().currentUser.getIdToken(true),
                    'X-Firebase-AppCheck': (await firebase.appCheck().getToken(/* forceRefresh= */ true)).token,
                },
                body: JSON.stringify(document)
            })
            .then((res) => {
               return res.json();
            })
            //throw error if bad response
            .then((res) => {
                if(!res.id) throw new Error(JSON.stringify(res))
                else return res.id
            })
            .catch((error) => {
                console.error(error)
                throw error
            });
}

export const updateDocument = async (collection, docId, document) => {
    if(!(firebase.auth().currentUser)) throw new Error("User not authorized.")
    
    return await fetch(`${process.env.REACT_APP_API_URL}/col/${collection}/doc/${docId}`,
            {
                method: 'put',
                headers: {
                    'Content-Type':'application/json', 
                    'Authorization': await firebase.auth().currentUser.getIdToken(true),
                    'X-Firebase-AppCheck': (await firebase.appCheck().getToken(/* forceRefresh= */ true)).token,
                },
                body: JSON.stringify(document)
            })
            .then((res) => {
                if(res.ok){
                    return true;
                }else{
                    //return error message in case of bad response
                    return res.json()
                }                    
            })
            //throw error if bad response
            .then((status) => {
                if(status!==true) throw new Error(JSON.stringify(status))
                else return true
            })
            .catch((error) => {
                console.error(error)
                throw error
            });
}

export const deleteDocument = async (collection, docId) => {
    if(!(firebase.auth().currentUser)) throw new Error("User not authorized.")
    
    return await fetch(`${process.env.REACT_APP_API_URL}/col/${collection}/doc/${docId}`,
            {
                method: 'delete',
                headers: {
                    'Content-Type':'application/json', 
                    'Authorization': await firebase.auth().currentUser.getIdToken(true),
                    'X-Firebase-AppCheck': (await firebase.appCheck().getToken(/* forceRefresh= */ true)).token,
                }
            })
            .then((res) => {
                if(res.ok){
                    return true;
                }else{
                    //return error message in case of bad response
                    return res.json()
                }                    
            })
            //throw error if bad response
            .then((status) => {
                if(status!==true) throw new Error(JSON.stringify(status))
                else return true
            })
            .catch((error) => {
                console.error(error)
                throw error
            });
}