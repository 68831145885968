import { useState } from "react";
import {Button} from 'react-bootstrap';
import CallVolumeChart from "./CallVolumeChart";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ShowIncidents from "./collections/incidents/ShowIncidents.js";

function CallHistory(props) {
    var localDate = new Date();
    localDate = new Date(localDate.getTime() - localDate.getTimezoneOffset()*60*1000)
    const currentYear = localDate.getFullYear();
    
    const [year,setYear] = useState(currentYear);
    
    const yearsToShowHistory = [];
    for(let i=0;i<5;i++){
        yearsToShowHistory.push(currentYear-i);
    }
    
    return (           
        <div style={{display: props.display?"block":"none"}}>   
            <div style={{textAlign:'center'}}>
            {
                yearsToShowHistory.map((year, idx) => (
                <Button className='m-2' key={idx} onClick={(e) => setYear(year)}>{year}</Button>   
                ))
            }
    
                <CallVolumeChart fromYear={year} toYear={year}/>

            </div>  

            <div className="mt-4" style={{textAlign:'center'}}><label>Incidents</label></div>
        
            <Row className="mb-2">
                <Col>
                    <ShowIncidents allowRangeSelection={true}/>
                </Col>
            </Row>
                
        </div>
        
    );
    
    
}
export default CallHistory;
