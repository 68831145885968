import { useEffect, useState, useRef } from "react";
import firebase from './firebase';

function StaticContent(props) {
    const contentPath = props.contentPath;

    const isLoaded = useRef(false);
    const [content, setContent] = useState([]);

    // Note: the empty deps array [] means
    // this useEffect will run once
    // similar to componentDidMount()
    
    useEffect(() => {
        firebase.appCheck().getToken(/* forceRefresh= */ true)
        .then((appCheckTokenRes) => {
          //console.log(appCheckTokenRes.token)
          fetch(process.env.REACT_APP_API_URL+"/obj"+contentPath,
          {
            method: 'get',
            headers: {'X-Firebase-AppCheck':appCheckTokenRes.token},
          }
          )
          .then(res => res.text())
          .then(
            (result) => {
              isLoaded.current = true;
              setContent(result);
              //console.log("fetched content");
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              isLoaded.current = true;
              setContent("error");
              console.error(error);
            }
          )
        })    
          
    }, [])
   
      return (
        <div style={{display: props.display?"block":"none"}}>
          
          {isLoaded.current ? <div dangerouslySetInnerHTML={{ __html: content }} />:<></>}
          
        </div>
    
      );
  }
  
  export default StaticContent;