import { useEffect, useState } from "react";
import DataForm from "./DataForm";
import { Modal, Image } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getDocumentById } from './../data/documents.js'

function Subscribe(props) {
    const [show,setShow] = useState(props.unSubscribe);
    const {email} = useParams()
    const [subscription,setSubscription] = useState({})

    useEffect(() => {
        if(email){
            getDocumentById('subscription', email)
            .then((doc) => {
                if(doc.id) setSubscription(doc)
                else setSubscription({email:email})
            })
            .catch((e) => {
                console.error(e)
                setSubscription({email:email})
            })
        }    
    },[email])
    
    return (
        <>
            
            <Image 
                src='/subscribe.png' 
                className='btn-primary' 
                style={{width:"3rem", display: props.unSubscribe?'none':'block' }} 
                onClick={(e) => setShow(true)}
                roundedCircle
            />
            
            <Modal
                show={show}
                onHide={(e) => setShow(false)}
                size="md"
                backdrop="static"
            >
                <Modal.Header closeButton className='text-dark'>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-dark'>
                    <DataForm 
                        docType='subscription' 
                        formAction='add' 
                        callbackSave={(e) => setShow(false)} 
                        callbackCancel={(e) => setShow(false)} 
                        doc={subscription}
                    />
                </Modal.Body>
            </Modal>

        </>
    )
}

export default Subscribe;