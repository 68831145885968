import { useEffect, useState } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Incident(props) {
    const incident = props.incident;
    const [show,setShow] = useState(props.show);
    const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);

    const deleteIncident = () => {
        props.deleteIncident(incident.id);
        setShowDeleteConfirmDialog(false);
    }

    useEffect(() => {
        
    },[])

    return (
        <div className='alert mb-1 p-0'>  
            <div onClick={(e) => setShow(!show)} className='alert-body m-0 p-1'>
                <Image src='/Incident.png' alt='alert' style={{width:"2rem" }} roundedCircle className='bg-warning m-1'/>
                <span><b>{`${(incident.ChiefComplaint?incident.ChiefComplaint:incident.TypeCode) + " @ "  + incident.Address } `}</b></span>
                <img src="/delete.png" alt='delete' 
                        onClick={(e) => setShowDeleteConfirmDialog(true)} 
                        style={{maxHeight:'25px', float:'right', display:props.userPrivileges?.includes('admin')?'':'none'}} 
                />
            </div>

            <Collapse in={show} className='alert-detail'>  
                <div>
                    <Row xs={1} md={2} >
                        <Col style={{height:'15rem'}}>
                            <iframe
                                width="100%"
                                height="100%"
                                style={{border: '0', filter: 'grayscale(100%) invert(95%) contrast(100%)'}}
                                referrerPolicy="no-referrer-when-downgrade"
                                src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&zoom=12&q="${incident.Address} ${incident.XSt}"&center=${incident.CallerLongitude},${incident.CallerLatitude}`}
                                allowFullScreen>
                            </iframe>
                        </Col>
                        <Col>
                            <div style={{overflowWrap:'break-word'}}>
                                {new Date(incident.startTime).toLocaleString()}<br></br>
                                <label>Type:</label> {incident.TypeCode}<br></br>
                                <label>Address:</label> {incident.Address}<br></br>
                                <label>XSt:</label> {incident.XSt}<br></br>
                                {incident.Description}
                            </div> 
                        </Col>
                    </Row>
                </div>                  
            </Collapse> 

            {/* Incident close confirmation dialog */}
            <Modal 
                show={showDeleteConfirmDialog}
                onHide={(e) => setShowDeleteConfirmDialog(false)}
                size="sm" 
                backdrop="static"  
                centered           
            >
                <Modal.Header closeButton ></Modal.Header>
                <Modal.Body>
                    Confirm marking incident as closed.
                </Modal.Body>

                <Button variant='danger' className='mb-2' onClick={(e) => deleteIncident()}>Delete</Button>
                <Button variant='secondary' className='mb-2' onClick={(e) => setShowDeleteConfirmDialog(false)}>Cancel</Button>
                
            </Modal>
        </div>
    )
}

export default Incident;