import { Container } from "react-bootstrap";
import { FaPhone, FaFax } from "react-icons/fa";
import { MdEmail, MdHomeWork } from "react-icons/md";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Config from "./resources/config.json";

function Footer(props) {
    return (
      <>
        <div id="Footer" className="bg-dark text-light" style={{fontSize:'0.9rem'}}> 
          <hr
              style={{
                  borderTop: "2px solid #bbb",
                  borderRadius: "5px",
              }}
          />
          <Container>
            <Row xs={1} md={3} className="g-4">

              {
                Config.footer.map((section,idx) => (
                <Col id={section.text}>
                  {
                  section.url ?
                  <a href={section.url}><h5>{section.text}</h5></a>
                  : <h5>section.text</h5>
                  }
                  {section.links.map((link,idx) => (
                     <div id={idx}>
                      <a href={link.url} > {link.text} </a>
                   </div> 
                  ))
                  }
                </Col>
                ))
              }

              <Col id="contact_Us">
                <h5> Contact Us</h5>
                {Config.ContactUs.address? <div><MdHomeWork /> Address: {Config.ContactUs.address}</div>:<></>}
                {Config.ContactUs.phone? <div><FaPhone /> Phone: {Config.ContactUs.phone}</div>:<></>}
                {Config.ContactUs.fax? <div><FaFax /> Fax: {Config.ContactUs.fax}</div>:<></>}
                {Config.ContactUs.email? <div><MdEmail /> Email: {Config.ContactUs.email}</div>:<></>}
              </Col>

              <Col id="social_media">
                {Config.SocialMedia.map((sm,idx) => (
                    <a id={sm.name} href={sm.url} target="blank"> <img src={`/${sm.name}.png`} alt={sm.name} style={{maxWidth:'50px'}}/></a>
                ))      
                }              
              </Col>

            </Row>
            
          </Container> 
          <div className="m-0 text-center bg-dark text-light">
          <hr
              style={{
                  height:'1px',
                  borderRadius: "5px",
              }}
          />
            <span className="m-3"><a href='/privacy.html' target="_blank" rel='noreferrer'>Privacy Policy</a></span>
            <span><a href='/memberportal' target="_blank" rel='noreferrer'>Member Portal</a></span>
          </div>
        </div>
        
        
        
      </>
  
    );
  }
  
  export default Footer;