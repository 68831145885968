import { useEffect, useState } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Row, Col } from 'react-bootstrap';
import LocationChooser from '../../widgets/LocationChooser';

function Alerts(props) {
    const alert = props.alert;
    const [show,setShow] = useState(props.show);
    const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
    
    const deleteAlert = () => {
        props.deleteAlert(alert.id);
        setShowDeleteConfirmDialog(false);
    }
    
    useEffect(() => {
       
    },[])

    return (
        <div className='alert mb-1 mt-1 p-0'>  
            <div onClick={(e) => setShow(!show)} className='alert-body m-0 p-1'>
                <Image src='/Alert.png' alt='alert' style={{width:"2rem" }} roundedCircle className='bg-warning m-1'/>
                <span><b>{`${alert.title} !!!`}</b></span>
                <img src="/delete.png" alt='delete' 
                        onClick={(e) => setShowDeleteConfirmDialog(true)} 
                        style={{maxHeight:'25px', float:'right', display:props.userPrivileges?.includes('admin')?'':'none'}} 
                />
            </div>
            <Collapse in={show} className='alert-detail'>   
                <div>
                    <Row xs={1} md={2}>
                        { alert['location.address'] ? 
                        <Col style={{height:'15rem'}}>
                         
                            <LocationChooser 
                                position={{lat: alert['location.coordinate'].lat, lng: alert['location.coordinate'].lng}} 
                                style={{height:'15rem'}}
                            />
                        </Col>
                        :<></>
                        }
                        <Col>
                            {alert['location.address'] ? 
                                <> <label>Address: </label> {alert['location.address']} </>
                                : <></>
                            }
                            <div style={{overflowWrap:'break-word'}}><label>Details: </label> {alert.description}</div> 
                        </Col>                    
                    </Row>  
                </div>    
            </Collapse>  

            {/* Delete Confirmation Dialog */}
            <Modal 
                show={showDeleteConfirmDialog}
                onHide={(e) => setShowDeleteConfirmDialog(false)}
                size="sm" 
                backdrop="static"  
                centered           
            >
                <Modal.Header closeButton ></Modal.Header>
                <Modal.Body>
                    Confirm delete alert.
                </Modal.Body>

                <Button variant='danger' className='mb-2' onClick={(e) => deleteAlert()}>Delete</Button>
                <Button variant='secondary' className='mb-2' onClick={(e) => setShowDeleteConfirmDialog(false)}>Cancel</Button>
                
            </Modal>  
        </div>
    )
}

export default Alerts;