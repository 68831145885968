import { Container, Row, Col, Modal, AlertLink, Alert } from "react-bootstrap";
import CallVolumeChart from "./CallVolumeChart";
import ImageLink from "./widgets/ImageLink";
import {Component } from "react";
import React from 'react';
import ShowIncidents from "./collections/incidents/ShowIncidents.js";


class Home extends Component {

  render() {  
    return (
   
        <div id="Home" style={{display: this.props.display?"block":"none"}}> 
          
          <Row className="mt-2 mb-2">
            <Col>
              <ShowIncidents fromTime={new Date().getTime() - 30*60*1000} />
            </Col>
          </Row>
          
          <Row className="mt-2 mb-2">
            <Col>
              <CallVolumeChart fromYear='2023' toYear='2024'/>
              <div style={{textAlign:'right', position:'relative', top:'-88%', right:'10%'}}>
                <label style={{fontSize:'0.8rem'}}><a href="#call_history" onClick={(e) => this.props.updateBody(e,"call_history")}>more calls history</a></label>
              </div>
            </Col>
          </Row>
          
          <Row xs={1} md={2} lg={4} className="g-2" >

            <Col key="news_n_events">
              <ImageLink url="/" onClick={(e) => this.props.updateBody(e,"news_n_events")}  icon="/static/News.png" image="/static/Incident_large.jpg" text="News & Events" />              
            </Col>   
            <Col key="join_us">
              <ImageLink url="/" onClick={(e) => this.props.updateBody(e,"join_us")}  icon="/static/join_us.png" image="/static/join_us.jpg" text="Join Us" />              
            </Col>  
            <Col key="new_station">
              <ImageLink url="/" onClick={(e) => this.props.updateBody(e,"new_station")}  icon="/static/new_station.png" image="/static/new_station.jpg" text="New Station" />              
            </Col>     

            <Col key="donate">
              <ImageLink url="/" onClick={(e) => this.props.updateBody(e,"donate")}  icon="/static/donate_icon.png" image="/static/donate.jpg" text="Donate" />              
            </Col> 
          </Row>
          
        </div>
              
  
    );
  }
  
}
  
export default Home;
