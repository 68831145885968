import { useEffect, useState } from "react";
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { getDocuments } from "../../data/documents";
import config from '../../resources/config.json';
import { Badge } from "react-bootstrap";
import Image from "react-bootstrap/Image";

const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]

//construct roles
const roles = {}
config.OrgStructure.departments.map((department) => {
    const departmentName = department.name
    department.categories.map((category) => {
        const categoryName = category.name
        category.roles.map((role) => {
            roles[role] = {
                category: categoryName,
                department: departmentName
            }
        })
    })
})

function Members(props) {

    const [orgStructure, setOrgStructure] = useState({});
    
    useEffect(() => {
        getDocuments('members','limit=500')
        .then((result) => {
            const orgStructure = {}
            result.map((member) => {
                if(member.startDt){
                    const d = new Date(member.startDt)
                    member.startDt = month[d.getMonth()] + " " +d.getFullYear()    
                }
                member.orgRoles.map((role) => {
                    
                    if(roles[role]){
                        const department = roles[role].department
                        const category = roles[role].category

                        if(!orgStructure.hasOwnProperty(department)) orgStructure[department] ={}
                        if(!orgStructure[department].hasOwnProperty(category)) orgStructure[department][category]={}
                        if(!orgStructure[department][category].hasOwnProperty(role)) orgStructure[department][category][role] = []

                        orgStructure[department][category][role].push(member)
                    }
                    
                })                
            });
            
            //deduplicate members for a certain department
            //the members must be shown in the order as defined in the org structure config 
            config.OrgStructure.departments.forEach((department) => {
                const membersForDepartment = []
                if(orgStructure.hasOwnProperty(department.name)){
                    department.categories.forEach((category) => {
                        if(orgStructure[department.name].hasOwnProperty(category.name)){
                            category.roles.forEach((role) => {
                                if(orgStructure[department.name][category.name].hasOwnProperty(role)){
                                    const membersForRole=[]
                                    orgStructure[department.name][category.name][role].forEach((member) => {
                                        if(!membersForDepartment.includes(member.id)){
                                            membersForDepartment.push(member.id)
                                            membersForRole.push(member)
                                        }
                                    })

                                    orgStructure[department.name][category.name][role] = Array.from(membersForRole)
                                }
                            })
                        }
                    })
                }
                
            })
            setOrgStructure(orgStructure);
            //console.log(orgStructure)

        })
        .catch((err) => {
            console.error(err)
            setOrgStructure({})
        })

    }, [])

   
    return (
    <>  
        <div id='ShowMembers' style={{textAlign: "center"}}><h4>Members</h4></div> 
        
        {
            config.OrgStructure.departments.map((department) => (
                orgStructure[department.name]?
                    <Container fluid className="mb-3 border border-4 rounded" >
                        <div style={{textAlign:'center'}}><label>{department.name}</label></div>
                        {   
                            department.categories.map((category) => (
                                orgStructure[department.name][category.name]?
                                    <Container fluid className="mb-3 border  border-2 rounded">
                                        <div style={{textAlign:'center'}}><label>{category.name}</label></div>
                                        <Row className="g-1 flex-nowrap overflow-auto justify-content-xs-left">
                                        {   
                                            category.roles.map((role) => (
                                                orgStructure[department.name][category.name][role] && orgStructure[department.name][category.name][role].length >0?         
                                                    orgStructure[department.name][category.name][role].map((member) => (
                                                        <Col key={member.id} xs="auto">
                                                            <Card className='border-0' style={{ maxWidth: '12rem' }} >
                                                                <div style={{ width: '10rem', height: '10rem' }}>
                                                                {member.isLifeTimeMember?  <Image src='life_member_icon.png' style={{width:"3rem", position:'absolute'}}/> : <></>}
                                                                <Card.Img className='mx-auto' style={{ width: '10rem', height: '10rem' }} variant="top" src={process.env.REACT_APP_API_URL+"/obj"+member.picUrl} />
                                                                </div>
                                                                <Card.Body>
                                                                    <Card.Text>
                                                                        <label>{member.fName}  {member.lName}</label>
                                                                        <div style={{fontSize:'0.8rem'}}>
                                                                            {
                                                                                member.orgRoles.map((memberRole) =>(
                                                                                    //show all the roles the member has only for this category
                                                                                    roles[memberRole]?.category === category.name && roles[memberRole]?.department === department.name ?
                                                                                    <>{memberRole}<br></br></>
                                                                                    :<></>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                        <div style={{fontSize:'0.8rem' }}>Member Since: {member.startDt?member.startDt:""}</div>
                                                                        
                                                                    </Card.Text>
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>
                                                        
                                                    ))     
                                                    :<></>
                                            ))
                                        }
                                        </Row>
                                    </Container>
                                    :<></>    
                                
                            ))
                        }
                    </Container>
                    :<></>      
                
            ))
        }  
        
    </>

    )
    
    ;
  }
  
  export default Members;