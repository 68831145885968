import DataForm from '../widgets/DataForm';

function AddMember(props) {

    
    return (
        <div style={{display: props.display?"block":"none"}}>
            <DataForm 
                title='Add Member' 
                docType='members'
                formAction='add' 
                callbackCancel={(m)=>props.updateBody('','home')}
                callbackSave={(m)=>props.updateBody('','home')}
                />
        </div>
    )
}

export default AddMember