import { useEffect, useState } from "react"
import PostDetail from "./PostDetail"
import { getDocumentById } from "../../data/documents"
import { useParams } from "react-router-dom"

function ShowPostDetail(props) {
    const {id} = useParams()
    const[post,setPost] = useState()

    useEffect(()=> {
        getDocumentById('posts',id)
        .then((post) => {
            setPost(post)
        })
        .catch((e) => {
            console.error(e)
            setPost({})
        })
    },[id])

    return (
        <>
        {post?.id?
            <PostDetail post={post} />
            :<></>
        }
        </>
    )
}

export default ShowPostDetail