import firebase from 'firebase/compat/app';
import 'firebase/compat/app-check';
import 'firebase/compat/analytics';

// Initialize the FirebaseUI Widget using Firebase.
const firebaseConfig = {
    apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
    authDomain: `${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}`,
    projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
    appId: `${process.env.REACT_APP_FIREBASE_APP_ID}`,
    measurementId: `${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`,
  };
firebase.initializeApp(firebaseConfig);

//initialize web analytics
firebase.analytics();

// Create a ReCaptchaEnterpriseProvider instance using your reCAPTCHA Enterprise
// site key and pass it to initializeAppCheck().
const appCheck = firebase.appCheck();
appCheck.activate(
  new firebase.appCheck.ReCaptchaEnterpriseProvider(
    /* reCAPTCHA Enterprise site key */
    `${process.env.REACT_APP_FIREBASE_APP_CHECK_KEY_ID}`
  )
 , true // Set to true to allow auto-refresh.
);

export default firebase;