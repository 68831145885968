import CreatePost from "../collections/posts/CreatePost";
import CreateAlert from "../collections/alerts/CreateAlert";

function AdminTools(props) {
    return(
        <div  style={{   
                float:'right',
                position: 'sticky',
                bottom: '0',
                display:props.userDetails?.privileges?.includes('admin') ? '' : 'none',
                maxWidth:'4rem',
                zIndex: '1'
            }}>
            
            <CreatePost updateBody={props.updateBody} />
            <CreateAlert userDetails={props.userDetails}/>
                   
            
        </div>

    );
}

export default AdminTools;