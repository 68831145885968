import { useState } from "react";
import { Container, Image, Button } from "react-bootstrap";
import ProgressBar from 'react-bootstrap/ProgressBar';

function NewStation(props) {
    const [now,setNow] = useState('3376986');

    return (
      <>
        <Container id='NewStation' style={{display: props.display?"block":"none"}}>
          <ProgressBar now={now} max={3546789} label={`$3,376,986 raised`} />
          <div style={{textAlign:'right'}}>
          <label>Required Budget: </label><b> $3,546,789</b>
          </div>
          <div style={{textAlign:'right'}}>
          <a href='https://www.paypal.com/donate/?hosted_button_id=75PZJ37NASJPA&sdkMeta=eyJ1cmwiOiJodHRwczovL3d3dy5wYXlwYWxvYmplY3RzLmNvbS9kb25hdGUvc2RrL2RvbmF0ZS1zZGsuanMiLCJhdHRycyI6eyJkYXRhLXVpZCI6IjAyOGMyNjE2NGNfbWptNm50aTZtenkifX0&targetMeta=eyJ6b2lkVmVyc2lvbiI6IjlfMF81OCIsInRhcmdldCI6IkRPTkFURSIsInNka1ZlcnNpb24iOiIwLjguMCJ9' target="_blank"><Button>Donate</Button></a>
          </div>
          
          <br></br>
          <Image fluid src={process.env.REACT_APP_PUBLIC_URL+'/NewStation.png'} alt="" />
          <ProgressBar now={10} max={100} label={`10% constructed`} />
          <br></br>
          
          
        </Container>
      </>
  
    );
  
}
  
export default NewStation;