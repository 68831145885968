import { useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import firebase from './firebase';

function Apparatus(props) {

    const [show, setShow] = useState(false);
    const [content, setContent] = useState([]);
    const isContentLoaded = useRef(false);
    
    const closeUploadForm = () => {
        setShow(false);
    }
    const showUploadForm = () => {
        setShow(true);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        
        document.getElementById('uploadStatus').innerHTML="uploading...";
        document.getElementById('uploadStatus').style.color="blue";
       
        //get idToken for the user
        const idToken = await firebase.auth().currentUser.getIdToken(true);

        //first upload image 
        var uploadSucess = false;
        const imageFile = form.apparatusPicture.files[0];
        let formData = new FormData();
        formData.append("file",imageFile);  
        
        firebase.appCheck().getToken(/* forceRefresh= */ true)
        .then(async (appCheckTokenRes) =>  {
            await fetch(process.env.REACT_APP_API_URL+"/obj/m/apparatus/"+imageFile.name,
            {
                method: 'post',
                headers: {
                    'Authorization':idToken, 
                    'X-Firebase-AppCheck':appCheckTokenRes.token, 
                },
                body: formData
            }
            )
            .then(
                (res) => {
                    if(res.ok){
                        uploadSucess = true;
                    }else{
                        uploadSucess = false;
                    }
                    
                },
                (error) => {
                    uploadSucess = false;
                    console.error(error);
                }
            )
        });

        //add a new Apparatus document
        if(uploadSucess){
            firebase.appCheck().getToken(/* forceRefresh= */ true)
            .then(async (appCheckTokenRes) =>  {
                fetch(process.env.REACT_APP_API_URL+"/col/apparatus/doc",
                {
                    method: 'post',
                    headers: {
                        'Content-Type':'application/json', 
                        'Authorization':idToken,  
                        'X-Firebase-AppCheck':appCheckTokenRes.token,
                    },
                    body: JSON.stringify({
                            title: form.apparatusTitle.value,
                            model: form.apparatusModel.value,
                            description: form.apparatusDescription.value,
                            images: ["/apparatus/"+imageFile.name]
                        })
                }
                )
                .then(
                    (res) => {
                        if(res.ok){
                            uploadSucess = true;
                        }else{
                            uploadSucess = false;
                        }
                        
                    },
                    (error) => {
                        uploadSucess = false;
                        console.error(error);
                    }
                )
            })
        }
        

        if (uploadSucess) {
            document.getElementById('uploadStatus').innerHTML="Success";
            document.getElementById('uploadStatus').style.color="green";
        }  else{
            document.getElementById('uploadStatus').innerHTML="Error in uploading";
            document.getElementById('uploadStatus').style.color="red";
        }  
    };

    //get all apparatus
    useEffect(() => {
        firebase.appCheck().getToken(/* forceRefresh= */ true)
        .then(async (appCheckTokenRes) =>  {
            fetch(process.env.REACT_APP_API_URL+"/col/apparatus",
            {
                method: 'get',
                headers: {
                    'X-Firebase-AppCheck':appCheckTokenRes.token,
                },
            }
            )
            .then(res => res.json())
            .then(
            (result) => {
                isContentLoaded.current = true;
                setContent(result);
            },
            (error) => {
                isContentLoaded.current = false;
                setContent("error");
                console.error(error);
            }
            )
        })
          
    }, [])

    
        return (
            <div style={{display: props.display?"block":"none"}}>
                <div style={{textAlign:"right", margin:"5px", display: props.userDetails?.privileges?.includes('admin')?'':'none'}}>
                    <Button variant="secondary" onClick={showUploadForm} >
                        Add Apparatus
                    </Button>
                </div>
                
                <Modal 
                    show={show} 
                    onHide={closeUploadForm} 
                    size="lg" 
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Add Apparatus</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form  onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="apparatusTitle">
                                <Form.Label>Title</Form.Label>
                                <Form.Control type="text" autoFocus/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="apparatusModel">
                                <Form.Label>Model</Form.Label>
                                <Form.Control type="text" />
                            </Form.Group>   
                            <Form.Group className="mb-3" controlId="apparatusDescription">
                                <Form.Label>Description</Form.Label>
                                <Form.Control as="textarea" rows={3} />
                            </Form.Group>  
                            <Form.Group controlId="apparatusPicture" className="mb-3">
                                <Form.Label>Upload Pictures</Form.Label>
                                <Form.Control type="file" accept="image/*" multiple/>
                            </Form.Group>
                            <Button variant="dark" type="submit">
                                Submit
                            </Button>
                            <span id="uploadStatus"></span>
                        </Form>

                    </Modal.Body>

                </Modal>
                
                
                { isContentLoaded.current ?
                    content.map((apparatus) => (
                        <Card key={apparatus.id} bg="dark" text="light">
                            <Carousel className="m-5" >
                            {   
                                apparatus.images.map((image) =>
                                    
                                    <Carousel.Item key={image}>
                                        <Image fluid className='mx-auto d-block' src={process.env.REACT_APP_API_URL+"/obj"+image} alt={image}></Image>
                                    </Carousel.Item>
                                    
                                )                               
                            }
                            </Carousel>
                            <Card.Body>
                                <Card.Title>{apparatus.title}</Card.Title>
                                <Card.Subtitle>{apparatus.model}</Card.Subtitle>
                                <Card.Text>{apparatus.description}</Card.Text>
                            </Card.Body>
                        </Card>
                        
                        )
                    )
                    : <></>
                }
                    
            </div>            
        );
    
}

export default Apparatus;