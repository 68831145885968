import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PostSnapsot from "./PostSnapshot";
import Collapse from 'react-bootstrap/Collapse';
import { getDocuments, deleteDocument } from '../../data/documents';
import PostDetail from "./PostDetail";
//import useRootClose from 'react-overlays/useRootClose';

function ShowPosts(props) {
  
  const [posts, setPosts] = useState([]);
  const [showPostDetails, setShowPostDetails] = useState(false);
  const [currentPost, setCurrentPost] = useState()
   
  const fetchPosts = (type) => {
    getDocuments('posts',`${type?`type=${type}`:""}&orderBy=occurrenceDate`)
    .then ( (results) => {
      setPosts(results);
    })
    .catch( (e) => {
      console.error(e);
      setPosts([]);
    });
  }

  const deletePost = (postId) => {
    deleteDocument('posts', postId)
    .then( (status) => {
      if(status) {
        const newPosts = [];
          for(let i=0; i<posts.length; i++){
            if(posts[i].id !== postId) {
              newPosts.push(posts[i]);            
            }
          }
          setPosts(newPosts);
      }
    })
    .catch((e) => {
      window.alert("Something went wrong. The post could not be deleted. Please try later.");
    })
    
  }

  const togglePost = (post) => {
    if(currentPost?.id === post.id && showPostDetails){
      setCurrentPost({})
      setShowPostDetails(true);
    }else{
      setCurrentPost(post)
      setShowPostDetails(true);
    }
      
  }

  //const postDetail = useRef({});
  //useRootClose(postDetail, hidePost, {
  //  disabled: !showPostDetails,
  //});

  useEffect(() => {
    //fetch posts
    console.log("fetching posts...")
    fetchPosts(props.postType); 
  },[props.postType]);
  
  return (
    <>
      <Container id={props.postType} style={{display: props.display?"block":"none"}}>

        <h5>{props.postType}</h5>
        <Row className="g-1 flex-nowrap overflow-auto">     
        {
          posts.map((post, _) => (
            <Col key={post.id}>
                <PostSnapsot 
                  post={post} 
                  deletePostCallback={deletePost} 
                  userPrivileges={props.userDetails?.privileges??[]}
                  onClick={(e) => togglePost(post)}
                  selected={true}
                />
                <div className="down-arrow" style={{display:currentPost?.id===post.id && showPostDetails?'block':'none', position:'relative', left:'2rem'}}></div>
        
            </Col>
          ))
        }
        </Row> 
        
        {
          posts.map((post, _) => (
            <Collapse in={currentPost?.id===post.id} className='post-detail mt-1' >
              <div>
                <PostDetail post={post} />
              </div>
            </Collapse>
          ))
        }
  
      </Container>
    </>

  );
  
}
  
export default ShowPosts;