import {useState} from 'react'
import schema from '../data/schema.json'
import { 
    Form,
    FloatingLabel,
    Container,
    Button,
    Alert,
} 
from 'react-bootstrap'
import { createDocument, updateDocument } from '../data/documents'
import { saveFile } from '../data/object'
import firebase from '../firebase'
import PictureInput from './PictureInput'
import FBInputChkBox from './FBInputChkBox'
import { createPost } from '../social_media/facebook'
import Config from '../resources/config.json'
import LocationChooser from './LocationChooser'


export const formAction = {
    ADD : "add",
    EDIT: "edit",
}

function postToFB(docType, id, newDoc) {
    if( document.getElementById('_fbPostId_')?.checked) {
        const fbPostMsg = newDoc[Config.fbPostMapping[docType]?.message]
        if(fbPostMsg) {
            const fbPostBody = {message : fbPostMsg}
            const fbPostLink = newDoc[Config.fbPostMapping[docType]?.link]
            if(fbPostLink){
                if(Array.isArray(fbPostLink)) {
                    if(fbPostLink[0]) fbPostBody.link=`${process.env.REACT_APP_API_URL}/obj${fbPostLink[0]}`
                }else {
                    fbPostBody.link=`${process.env.REACT_APP_API_URL}/obj${fbPostLink}`
                }
            }

            createPost(fbPostBody,(fbPostId) => {
                    console.log("FB postID:"+fbPostId)
                    //update post with FB  post ID
                    console.log("updating doc:"+id)
                    updateDocument(docType,id,{'_fbPostId_':fbPostId})
                }
            )
        }
    }
}

function DataForm(props) {
    const doc = props.doc
    const docType = props.docType
    const action = props.formAction
    var docIdField

    const [submitStatus, setSubmitStatus] = useState("")
    //const submitStatus = useRef()
    const [show,setShow] = useState(true)
    
    const submitForm = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        setSubmitStatus("in progress ....")
        setShow(false)

        try{
            
            const form = event.currentTarget;

            const inputs = form.querySelectorAll('input,select,textarea')
            var newDoc = {}
            for(var input of inputs) {
                formInputToObject(newDoc,input.id,input)
            }
            console.log(newDoc)


            //save all file objects first
            var filesSaved = true;
            
            const fileInputs = form.querySelectorAll(`[type='file']`)
            for(const fileInput of fileInputs){
                for (const file of fileInput.files){
                    //some devices may have netwrok bandwith restrictions. So lets upload the images one by one.
                    const uploadStatus = await saveFile(file, docType)
                    if(!uploadStatus) {
                        filesSaved = false
                        setSubmitStatus(<Alert variant='danger'>{`Failed to upoad the file ${file.name}.`}</Alert>)   
                        continue
                    }else{
                        setSubmitStatus(<Alert variant='success'>{`Sucessfully uploaded file ${file.name}.`}</Alert>)
                    }
                }

                //don't upload other files if the previous file could not be uploaded
                if(!filesSaved) continue
            }
            
            console.log("all files uploaded ? "+filesSaved)
            
            if(filesSaved){
                var status = false
                switch(action) {
                    case formAction.ADD:
                        if(docIdField && newDoc[docIdField]){
                            newDoc.id = newDoc[docIdField]
                            status = await updateDocument(docType,newDoc.id,newDoc)                            
                        }else{
                            status = await createDocument(docType,newDoc)
                            .then((id) => {
                                if(id) {
                                    newDoc.id = id   
                                }                
                                return true
                            })
                        }

                        if(status && newDoc.id) {
                            postToFB(newDoc.id,newDoc)                      
                        }

                        break

                    case formAction.EDIT:
                        status = await updateDocument(docType,doc.id,newDoc)
                        break   

                    default:
                        console.error(<Alert variant='danger'>{`Invalid form action ${action}`}</Alert>) 
                        setSubmitStatus(<Alert variant='danger'>{`Invalid form action ${action}`}</Alert>)  
                        return 
                }

                if(status) {
                    setSubmitStatus(<Alert variant='success'>{"Sucessfully saved data."}</Alert>)
                    //check if there is any fucntion to call back.
                    props.callbackSave?.(newDoc)
                }
                else {
                    setSubmitStatus(<Alert variant='danger'>{"Error in saving the data. "}</Alert>)
                }
            }else{
                setSubmitStatus(<Alert variant='danger'>{"Error in upoading the files."}</Alert>)
            }

        
        }catch(e) {
            console.error(e)
            setSubmitStatus(<Alert variant='danger'>{e.message}</Alert>)
        }

    }

    //build a JSON object from the form inputs
    const formInputToObject = (obj,inputId, inputElement) => {
        const idx = inputId.indexOf(":")
        if(idx >= 0) {
            const key = inputId.substring(0,idx)
             if(!obj[key]) {
                obj[key] = {}
             }  
             formInputToObject(obj[key],inputId.substring(idx+1),inputElement)

        }else{
            var value 
            switch (inputElement.type) {
                case 'file':
                    if(inputElement.multiple){
                        const fileNames = []
                        for (const file of inputElement.files) {
                            fileNames.push(`/${docType}/${file.name}`)
                        }
                        value = fileNames
                    }else{
                        value = `/${docType}/${inputElement.files[0]?.name}`
                    }
                    
                    break
                
                case 'checkbox':
                    if(inputElement.multiple) {
                        value = obj[inputId]??[]
                        if(inputElement.checked) {
                            value.push(inputElement.name)
                        }
                    }else{
                        if(inputElement.checked){
                            value = true
                        }else{
                            value = false
                        }
                    }    
                    break;
                default: 
                    value = inputElement.value    
            }

            //get values from multi select options
            if(inputElement.tagName === 'SELECT' && inputElement.multiple) {
                value = Array.from(inputElement.options).filter( (option) => {
                            return option.selected;
                        }).map( (option) => {
                            return option.value;
                        });
            }

            if(inputElement.getAttribute('is') === 'coordinate') {
                value = JSON.parse(value)
            }
                        
            obj[inputId] = value
        }

    }

    const renderInputField = (field, parentFieldId, data) => {
        const fieldId = parentFieldId ? (parentFieldId + ":" + field.name) : field.name
        const required = field.required??false
        const disabled = action===formAction.ADD ? false: !(field.editable??true)
        const defaultValue = data? data[field.name] : field.defaultValue
        
        if(field.isDocumentId) docIdField = field.name   
        
        switch (field.type) {
            case 'hidden': 
                var value =getValueForReservedField(field.value)
                return (
                    <FloatingLabel className="mb-3" controlId={fieldId} label={field.description??field.name}>
                        <Form.Control 
                            type="hidden" 
                            value={value}
                        /> 
                    </FloatingLabel>
                ) 

            case 'textarea': 
                return (
                    <FloatingLabel className="mb-3" controlId={fieldId} label={field.description??field.name}>
                        <Form.Control 
                            as="textarea" 
                            style={{height:'8rem'}} 
                            required={required} 
                            disabled={disabled} 
                            defaultValue={defaultValue}
                        /> 
                    </FloatingLabel>
                )

            case 'timestamp': 
                return (
                    <FloatingLabel className="mb-3" controlId={fieldId} label={field.description??field.name}>
                        <Form.Control 
                            type="datetime-local" 
                            required={required} 
                            disabled={disabled}
                            defaultValue={defaultValue}
                        /> 
                    </FloatingLabel>
                ) 

            case 'map':
                return (
                    <Container fluid className="mb-3 border rounded" >
                        <Form.Label >{field.description??field.name}</Form.Label>
                        {field.subFields.map((subField,idx) => (
                            renderInputField(subField,fieldId,defaultValue)
                        ))                          
                        }
                    </Container> 
                )   
            
            case 'select': 
                return (
                    <FloatingLabel controlId={fieldId} label={field.description??field.name} className="mb-3" >
                        <Form.Select 
                            defaultValue={defaultValue} 
                            multiple={field.multiple??false} 
                            style={{
                                height: field.multiple??false?`${Object.keys(field.values)?.length + 2.5}rem`:'1rem',
                                maxHeight: '10rem'
                            }}
                            disabled={disabled}
                        >
                            {
                                Object.keys(field.values)?.map((key,idx) => (
                                <option key={idx} value={key} > {field.values[key]} </option>
                                ))
                            }
                            
                        </Form.Select>
                    </FloatingLabel>
                )

            case 'checkbox':
                return (
                    <Container fluid className="mb-3 border rounded" >
                        <label>{field.description??field.name}</label>
                        {field.multiple ?                
                        Object.keys(field.values)?.map((key,idx) => (
                            <div key={idx} >
                                <input 
                                    type='checkbox'
                                    id={fieldId} 
                                    name={key} 
                                    value={key}
                                    defaultChecked={defaultValue?.includes(key)}
                                    disabled={disabled}
                                    multiple={true}
                                /> 
                                {` ${field.values[key]}`}
                            </div>
                        ))                   
                        :                        
                        <input
                            type='checkbox'
                            id={fieldId} 
                            name={field.description??field.name}
                            disabled={disabled}
                            defaultChecked={defaultValue}
                        /> 
                        }
                    </Container>
                )    
            
            case 'file': 
                if(field.picture){
                    return (
                        <div className="mb-3">
                            {field.description??field.name}
                            <PictureInput src={`${process.env.REACT_APP_API_URL}/obj${defaultValue}`} />
                        </div>
                        
                    )
                } else {
                    return (
                        <FloatingLabel className="mb-3" controlId={fieldId} label={field.description??field.name}>
                            <Form.Control 
                                type="file" 
                                required={required} 
                                multiple={field.multiple??false}
                                disabled={disabled}
                                defaultValue={defaultValue}
                            /> 
                        </FloatingLabel>
                    ) 
                }
            
                   
            case 'fbPost' :
                return (
                    <FBInputChkBox id={fieldId} />
                )

            case 'location' :
                return (
                    <Container fluid className="mb-3 border rounded" >
                        <Form.Label >{field.description??field.name}</Form.Label>
                        <LocationChooser id={fieldId} style={{height:'20rem'}} editable={true}/>
                    </Container>
                   
                )

            default:
                return (   
                    <FloatingLabel className="mb-3" controlId={fieldId} label={field.description??field.name}>
                        <Form.Control 
                            type="text" 
                            required={required}
                            disabled={disabled}
                            defaultValue={defaultValue}
                            style={{textTransform:field.caseSensitive??true?'':'lowercase'}}
                        />
                    </FloatingLabel>
                )
        }
    }

    return (
        <>  
            <h2 className='text-center'>{props.title}</h2>
            <Form id={docType} onSubmit={submitForm} >
                <div style={{display:show?'block':'none'}}>
                {schema[docType].map((field,idx) => (
                    <div key={idx}>
                        {renderInputField(field,"", doc)}
                    </div>
                ))                    
                }   
                
                <Button className='m-2' variant="primary" type="submit" > Save </Button>
                <Button variant="secondary" onClick={(e) => props.callbackCancel?.()}> Cancel </Button>
                </div>
                <div>{submitStatus}</div>
            </Form>
        </>
    )

}

export default DataForm;

function getValueForReservedField(field) {
    switch (field) {
        case '__user__' : return firebase.auth().currentUser.email
        default: return ''
    }
}