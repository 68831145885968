import {authenticateFB} from "../social_media/facebook"

function FBInputChkBox(props) {

    const chkBoxChanged = (e) => {
        if (e.currentTarget.checked) {
            authenticateFB();
          } 
    }

    return (
        <div>
            <label> Post to Facebook?</label>
            <input type="checkbox" id={props.id} value="" onChange={(e) => chkBoxChanged(e)}/>
            
        </div>
    )
}

export default FBInputChkBox