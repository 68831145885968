import { useEffect, useState } from "react"
import Login from "./Login"
import ListMembers from "../collections/members/ListMembers"
import firebase from "../firebase"
import { getUserDetails } from "./UserAccount"
import Container from 'react-bootstrap/Container'
import AddMember from "./AddMember"
import Apparatus from "../Apparatus"
import Header from "./Header"
import AdminTools from "./AdminTools"
import UserAccount from "./UserAccount"
import Config from '../resources/config.json'
import ShowPosts from "../collections/posts/ShowPosts"
import ShowAlerts from "../collections/alerts/ShowAlerts"
import Footer from "../Footer"

function MemberPortal(props) {

    const [bodyType,setBodyType] = useState("home")
    const [userDetails, setUserDetails] = useState({})

    const updateBody = (event,bodyType)  => {
        if(event) event.preventDefault();
        const a = bodyType.split('#');
        setBodyType(a[0])

        //scroll to the requested element
        const elementToScrollIntoView = document.getElementById(a[1]); 
        if(elementToScrollIntoView) {
          elementToScrollIntoView.scrollIntoView()  
        }          
    } 

    useEffect(() => {
        firebase.auth().onAuthStateChanged(async (user) => {
            if(user){
              const userProfile = await getUserDetails()
              if(userProfile.email) {
                setBodyType('home')
                setUserDetails(userProfile)
              } else {
                alert("User profile is not created. Please contact your administrator.");
                firebase.auth().signOut();
                setBodyType('login')
                setUserDetails({})    
              }     
            }else{
                setUserDetails({})
            }
          }); 

    },[])

    return (
        <>
            <Header 
                expand="xl"
                updateBody={updateBody}
                bodyType={bodyType}
                userDetails={userDetails} 
              />
              
            <Container fluid='md' id='_body' >
                
                <div id='alerts-conatiner'><ShowAlerts userDetails={userDetails} /></div>
                {bodyType==='home' ? <ListMembers display={true} updateBody={updateBody} userPrivileges={userDetails?.privileges??[]} />:<></>}
                <AddMember display={bodyType==='addMember'?true:false} updateBody={updateBody}/>   
                <UserAccount display={bodyType==="user_account" ? true : false} user={userDetails}/>

                {/* ---------- All dynamic pages : start --------- */}
                {
                  Config.postTypes.map((postType,idx) => (
                    // Re-render in order to re-fetch all posts each time the page is visited.
                    bodyType===postType.type ? 
                    <ShowPosts 
                      key={postType.type} 
                      display={true} 
                      postType={postType.type} 
                      userDetails={userDetails} 
                    /> 
                    : <></>
                  ))
                }
                {/* ---------- All dynamic pages : end --------- */}
                <ShowPosts display={bodyType==="news_n_events"?true: false} userDetails={userDetails} />
                <Apparatus display={bodyType==="apparatus"?true: false} userDetails={userDetails} />

                <AdminTools updateBody={updateBody} userDetails={userDetails}/>
                <Login display={bodyType==='login'?true:false}/>
            </Container>
            <Footer updateBody={updateBody}/>
                
        </>
    )

}

export default MemberPortal;