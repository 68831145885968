import { useEffect, useState } from 'react';
import Alert from './Alert';
import firebase from '../../firebase';
import { Row, Col } from 'react-bootstrap';
import { deleteDocument } from '../../data/documents';

function ShowAlerts(props) {
    const [alerts,setAlerts] = useState([]);

    const getAlerts = async () => {
        await fetch(`${process.env.REACT_APP_API_URL}/alerts`,
            {
                method: 'get',
                headers: {
                    'X-Firebase-AppCheck': (await firebase.appCheck().getToken(/* forceRefresh= */ true)).token,
                },
            }
            )
            .then(res => res.json())
            .then( (res) => {
                setAlerts(res);
            })
            .catch((e) =>{
                console.error(e)
            })
    }

    const deleteAlert = async (alertId) => {
      deleteDocument('alerts',alertId)
      .then((status) => {
        if(status) {
          const newAlerts = [];
              for(let i=0; i<alerts.length; i++){
                if(alerts[i].id !== alertId) {
                    newAlerts.push(alerts[i]);            
                }
              }
              setAlerts(newAlerts);
        }
      })
      .catch((e) => {
        window.alert("Something went wrong. The alert could not be deleted. Please try later.");
      })
      
      }

    useEffect(() => {
        getAlerts();
    },[props.userDetails])

    return (
        <div>
            <Row xs={1}>
            
            {
                alerts.map((alert,idx) => (
                    <Col key={idx}>
                        <Alert 
                          alert={alert} 
                          userPrivileges={props.userDetails?.privileges??[]} 
                          deleteAlert={deleteAlert}
                          show={idx===0?true:false}
                        />
                    </Col>
                ))
            }
            </Row>
            
        </div>
    )
}

export default ShowAlerts;