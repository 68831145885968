import firebase from '../firebase';
import Member from '../collections/members/Member';

export const getUserDetails = async function () {
    const currentUser = firebase.auth().currentUser;
    var userDetails = {};
    if (currentUser) {
        
        userDetails = await fetch(`${process.env.REACT_APP_API_URL}/col/members?email=${currentUser.email}`,
            {
                method: 'get',
                headers: {
                    'X-Firebase-AppCheck': (await firebase.appCheck().getToken(/* forceRefresh= */ true)).token,
                    'Authorization': await currentUser.getIdToken(true),
                },
            }
            )
            .then(res => res.json())
            .then(
            (result) => {
                if(result[0]) {
                    return result[0];
                }else{
                    return {};
                }
                
            })
            .catch((error) => {
                console.error(error);
                return {};
            }); 
                    
    } else {
        // No user is signed in.
        userDetails = {};
    }
    
    return userDetails;
}

function UserAccount(props) {
    
    const currentMember = props.user;
    
    return (
        <div style={{display: props.display?"block":"none"}} >
            {currentMember ? 
                <Member  member={currentMember} snapshot={false}/>                    
            :<></>
            }
        </div>
    );
}

export default UserAccount;